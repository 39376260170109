@import 'node_modules/microtip/microtip';

#sticky-social-icons-container{
    position: fixed;
    z-index: 999;
    text-align: center;

    ul, li{
        margin: 0;
        padding: 0;
    }

    li{
        list-style: none;

        a{
            transition: 0.1s;
            transition-timing-function: linear;
            text-align: center;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            display: flex;

        }

    }
}

#sticky-social-icons-container.design-rounded.alignment-left {

    li:first-child a{
        border-radius: 0 5px 0 0;
    }

    li:first-child:last-child a{
        border-radius: 0 5px 5px 0;
    }

    li:last-child a{
        border-radius: 0 0 5px 0;
    }

    &.with-animation{
        li a:hover{
            border-radius: 0 5px 5px 0 !important;
        }
    }
}

#sticky-social-icons-container.design-rounded.alignment-right{
    li:first-child a{
        border-radius: 5px 0 0 0;
    }

    li:first-child:last-child a{
        border-radius: 5px 0 0 5px;
    }

    li:last-child a{
        border-radius: 0 0 0 5px;
    }

    &.with-animation{
        li a:hover{
            border-radius: 5px 0 0 5px !important;
        }
    }
}

#sticky-social-icons-container.alignment-left{
    left: 0;
}

#sticky-social-icons-container.alignment-right{
    right: 0;
}


// enable animation
#sticky-social-icons-container.alignment-left.with-animation{
    li a:hover{
        margin-right: -10px;
    }
}

#sticky-social-icons-container.alignment-right.with-animation{
    li a:hover{
        margin-left: -10px;
    }
}

@media( max-width: 767px ){

    // hide in mobile
    #sticky-social-icons-container.hide-in-mobile{
        display: none !important;
    }

    // hide tooltip in smaller screen.
    [role~=tooltip][data-microtip-position=right]:hover:after, [role~=tooltip][data-microtip-position=right]:hover:before{
        display: none;
    }

}